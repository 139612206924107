import $ from 'jquery';

import './header.css';
import './toggle_menu.css';

import './static/search_icon.svg';
import './static/header_logo.svg';

import throttle from 'lodash.throttle';

function progressBar() {
  let progressBar = document.getElementById('progressBar');
  let top = document.getElementById('top');
  let bottom = document.getElementById('endArticle');
  let percent = document.getElementById('percent');

  let startPosition = top.offsetTop - 100;
  let stopPosition = bottom.offsetTop - 100;

  function updatePosition() {
    let pos = document.documentElement.scrollTop || document.body.scrollTop;

    if (pos < startPosition) {
      progressBar.style.opacity = '0';
      percent.style.width = 0;
      progressBar.style.zIndex = '0';
    } else if (pos >= startPosition && pos <= stopPosition) {
      let pWidth = Math.ceil(((pos - startPosition) / (stopPosition - startPosition)) * 100);
      progressBar.style.opacity = '1';
      percent.style.width = pWidth + '%';
      progressBar.style.zIndex = '1000';
    } else {
      percent.style.width = '100%';
      progressBar.style.opacity = '0';
      progressBar.style.zIndex = '0';
    }
  }

  document.addEventListener(
    'scroll',
    throttle(updatePosition, 150),
    {passive: true}
  );
}

// FIXME. REWRITE ALL BULLSHIT BELOW.
document.addEventListener('DOMContentLoaded', function () {
  var header = document.getElementById('header');
  var logo = document.getElementById('headerLogo');
  var elements = document.getElementsByClassName('with_child');

  Array.prototype.forEach.call(elements, (element) => {
    element.addEventListener('mouseover', function () {
      header.classList.add('header-large');
    });
    element.addEventListener('mouseout', function () {
      header.classList.remove('header-large');
    });
  });

  var stuck = false;


  function getDistance() {
    var topDist = header && header.offsetTop;
    return topDist;
  }

  if (header && header.classList.contains('fixed')) {
    window.addEventListener(
      'scroll',
      throttle(changeLogo, 150),
      { passive: true }
    );

    function changeLogo(e) {
      var offset = window.pageYOffset;
      var distance = getDistance() - offset;

      if (distance <= -360 && !stuck) {
        $(header).addClass('is-fixed');
        header.style.position = 'fixed';
        header.style.top = '0px';
        header.style.boxShadow = '0px -2px 4px 0px #666666';
        stuck = true;
        logo.classList.add('header__logo-small');
      }

      if (stuck && offset <= 360) {
        $(header).removeClass('is-fixed');
        header.style.position = 'absolute';
        header.style.boxShadow = 'none';
        logo.classList.remove('header__logo-small');
        stuck = false;
      }
    };
  } else {
    progressBar();
  }
});

$(document).ready(function () {
  var container = $('.header__icon_search');

  $('.form__search_open').on('click', function () {
    $('.form__search').addClass('form__search-open');
    $('.form__search input').focus();
  });

  $('.form__search_close').on('click', function () {
    $('.form__search').removeClass('form__search-open');
  });

  $(document).mouseup(function (e) {
    if (container.has(e.target).length === 0) {
      $('.form__search').removeClass('form__search-open');
    }
  });

  var toggle = document.getElementById('toggle_menu__item');

  $('.toggle_menu').click(function () {
    document.getElementById('headerLogo').scrollIntoView();

    $('.toggle_menu__item').toggleClass('toggle_menu__item-active');
    if (toggle.classList.contains('toggle_menu__item-active')) {
      $('.header__container').addClass('header__container-open');
      $('body').addClass('body__overflow-hidden');
    } else {
      $('.header__container').removeClass('header__container-open');
      $('body').removeClass('body__overflow-hidden');
    }
  });
});
