import $ from 'jquery';
import { Controller } from 'stimulus';
import * as Lib from 'jQuery/admin';
export default class extends Controller {
  static targets = ['success', 'error', 'fullForm'];

  connect() {
    this.success = $(this.successTarget);
    this.error = $(this.errorTarget);

    this.fullForm = this.hasFullFormTarget && $(this.fullFormTarget);
  }

  closePopups() {
    this.success.removeClass('popup-open');
    this.error.removeClass('popup-open');
  }

  onSuccess(res) {
    this.success.addClass('popup-open');
  }

  onError({ detail }) {
    const [data, status, xhr] = detail;

    if (this.fullForm.length > 0) {
      this.fullForm.html(data);
      Lib.Selectize(this.fullForm);
    }
    // this.error.addClass('popup-open');
  }
}
