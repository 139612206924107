import $ from 'jquery';

import SimpleLightbox from "simplelightbox";
import 'simplelightbox/dist/simple-lightbox.css';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './gallery/gallery.css';

import './static/loader.svg';

$(document).ready(function () {
  const $container = $('.article');
  new SimpleLightbox('.lightbox');
  const $slick = $container.find('.attachment-gallery');
  $slick.slick();
});

