import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['loader', 'container', 'btn'];

  connect() {
    this.container = $(this.containerTarget);
  }

  beforeSend() {
    $(this.loaderTarget).show();
  }

  onSuccess({ detail }) {
    const [data, status, xhr] = detail;

    $(this.btnTarget).remove();
    this.container.append(data);

    window.history.pushState('string', document.title, xhr.responseURL);
  }
}
