import $ from 'jquery';
import './index.css';

import './static/rectangle1.svg';
import './static/rectangle2.svg';

$(document).ready(function () {
  $('.magazines__carousel').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    dots: true,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          dots: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  });
});
