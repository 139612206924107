import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['count', 'carousel', 'navigation'];

  connect() {
    const $carouselTarget = $(this.carouselTarget);
    const $count = $(this.countTargets);
    const $navigation = this.hasNavigationTarget && $(this.navigationTarget);

    $carouselTarget.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      $count.text(i + '/' + slick.slideCount);
    });

    if (this.hasNavigationTarget) {
      $carouselTarget.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.gallery__carousel_nav',
      });

      $navigation.slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.gallery__carousel',
        dots: false,
        focusOnSelect: true,
      });
    } else {
      $carouselTarget.slick({
        dots: false,
      });
    }
  }
}
