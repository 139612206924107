import $ from 'jquery';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$(document).ready(function () {
  const $container = $('.js-hero');
  const $slick = $container.find('.js-carousel');
  const $slides = $container.find('.js-carouselSlide');
  const $sponsor = $container.find('.js-sponsor');

  $slick.slick({
    infinite: false,
    prevArrow: false,
    nextArrow: false,
    speed: 200,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  });

  $.map($slides, function (slide, i) {
    $(slide)
      .off('mouseover')
      .on('mouseover', function (e) {
        $slick.slick('slickGoTo', i);
        $slick.slick('slickPause');
      });
  });

  $slick.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $slides.removeClass('hero__topic-active');
    $($slides.get(nextSlide)).addClass('hero__topic-active');
  });

  $slick.on('afterChange', function (event, slick, index) {
    if (index === 2) {
      $slick.slick('slickPause');
    }
  });

  // Hide Sponsor
  setTimeout(hideSponsor, 7000);

  function hideSponsor() {
    $sponsor.removeClass('sponsor-active');
  }
});
