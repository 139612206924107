import $ from 'jquery';
require('jquery-datetimepicker');
import 'jquery-datetimepicker/jquery.datetimepicker.css';

function DatePicker($container) {
  $.each($container.find('.js-datetimepicker'), (index, item) => {
    const $item = $(item);

    return $item.datetimepicker({ format: 'm/d/Y H:i' });
  });

  $.each($container.find('.js-datepicker'), (index, item) => {
    const $item = $(item);

    return $item.datetimepicker({ format: 'm/d/Y', timepicker:false });
  });
}

export default DatePicker;
