import $ from 'jquery';

$(document).ready(function () {
  $('.trending_topics__carousel').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,

          centerPadding: '40px',
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,

          centerPadding: '40px',
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,

          centerPadding: '40px',
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
