import $ from 'jquery';
import './index.css';

$(document).ready(function () {
  $('.rank__tr').off('click').on('click', function (e) {
    e.preventDefault();

    if($(this).hasClass('rank__tr-active')){
      $(this).toggleClass('rank__tr-active')
      var elem = $(this).next('.rank__details')
      return elem.slideToggle(100);
    }

    $('.rank__tr').removeClass('rank__tr-active')
    $('.rank__details').slideUp(100)
    $(this).toggleClass('rank__tr-active')
    var elem = $(this).next('.rank__details')
    elem.slideToggle(100);
  })
})
