import $ from 'jquery';
require('selectize');

import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.bootstrap3.css';

function Selectize($container) {
  $container.find('select').map((index, element) => {
    const create = $(element).data('create') || false;
    const placeholder =  $(element).data('placeholder') || 'Click here to select…';
    $(element).selectize({
      allowEmptyOption: true,
      create,
      placeholder,
    });
  });
}

export default Selectize;
