import $ from 'jquery';
import Cookies from 'js-cookie';

import './dfp.css';

const firstVisit = Cookies.get('firstVisit');

$(document).ready(function () {
  if (!firstVisit) {
    var closeBtn = $('#dfp-overlay');
    var ppCloseBtn = $('#pp-close');

    setTimeout(function () {
      $('#dfp').addClass('dfp-open');
      $('#pp').addClass('pp-open');
      Cookies.set('firstVisit', false, { expires: 1 });
    }, 400);

    closeBtn.on('click', function () {
      $('#dfp').removeClass('dfp-open');
    });

    setTimeout(function () {
      $('#dfp').removeClass('dfp-open');
    }, 8000);

    ppCloseBtn.on('click', function () {
      $('#pp').remove()
    });
  }
});
