import $ from 'jquery';
import { intersection } from 'ramda';

function OptionalFields($container) {
  $container.find('[data-if]').map((index, element) => {
    const $element = $(element);
    const $parent = $container.find($element.data('if'));

    $parent.on('change.dataIf', (event) => {
      const $target = $(event.target);

      const selected = (() => {
        if ($target.is('[type=checkbox]') || $target.is('[type=radio]')) {
          return $target.is(':checked') ? $target.val() : '';
        }
        return $target.val();
      })();

      if (intersection([String(selected)], String($element.data('if-value')).split('|')).length !== 0) {
        $element.show();
        $element.find('input[data-disabled!=true], select[data-disabled!=true]').attr('disabled', false);
        $.each($element.find('select'), function (index, el) {
          try {
            return el.selectize.enable();
          } catch (_error) {
            return false;
          }
        });
      } else {
        $element.hide().find('input, select').attr('disabled', true);
      }
    });

    if ($parent.is('[type=radio]') && $parent.filter(':checked').length > 0) {
      return $parent.filter(':checked').trigger('change.dataIf');
    }

    return $parent.trigger('change.dataIf');
  });
}

export default OptionalFields;
