import Rails from '@rails/ujs';
import $ from 'jquery';

import 'sharer.js';

require.context('../images', true);

// FIXME
import 'stylesheets/application/application.css';

import 'components/shared';
import * as Lib from 'jQuery/application';
import 'controllers/application.js';

// Pages
import 'components/articlepage/show';
import 'components/categoriespage/show';
import 'components/errorpage/index';
import 'components/homepage/index';
import 'components/searchpage/index';
import 'components/subscribepage/index';
import 'components/magazinespage/index';
import 'components/topicpage/show';
import 'components/webinarpage/show';
import 'components/ranks/index';

window.jQuery = $;
window.googletag = window.googletag || {cmd: []};

Rails.start();
Lib.GoogleTag();

$(() => {
  const $container = $(document);
  Lib.Selectize($container);
});

$(document).ready(function () {
  $('a.load-more').click(function (e) {
    e.preventDefault();

    var urlString = window.location.href;
    var urlParams = new URL(urlString);
    var currentPage = urlParams.searchParams.get('page');
    var url = $(this).attr('href');
    var pageCount = Number(currentPage);

    if (currentPage === null) {
      pageCount += 2;
    } else {
      pageCount += 1;
    }

    $('.load-more').hide();
    $('.loading-gif').show();
    $.ajax({
      type: 'GET',
      url: $(this).attr('href'),
      data: {
        page: pageCount,
      },
      dataType: 'script',

      success: function () {
        $('.loading-gif').hide();
        $('.load-more').show();
        window.history.pushState('string', document.title, url + '?page=' + pageCount);
      },
    });
  });

  // WIP
  const stories = $('.trix-content').find('address');
  if (stories.length > 0) {
    stories.html($('#relatedStories'));
  }

  const banner = $('section').find('abbr');
  if (banner.length > 0) {
    banner.empty();
    banner.addClass('banner_article').attr('id', 'div-gpt-ad-6390982-8');
    googletag.cmd.push(function () {
      googletag.display('div-gpt-ad-6390982-8');
    });
  }

  // Нельзя так делать, iframe необязательно видео, это может быть quizz
  // https://nationaljeweler.dev.jetrockets.pro/quizzes/4-week-of-february-1st
  // $('pre iframe').wrapAll('<div class="video-container">');
});

// Refresh page at 8:00 AM
// function refreshAt(hours, minutes, seconds) {
//   var now = new Date();
//   var then = new Date();

//   if(now.getHours() > hours ||
//      (now.getHours() == hours && now.getUTCMinutes() > minutes) ||
//       now.getHours() == hours && now.getUTCMinutes() == minutes && now.getUTCSeconds() >= seconds) {
//       then.setUTCDate(now.getUTCDate() + 1);
//   }
//   then.setHours(hours);
//   then.setMinutes(minutes);
//   then.setSeconds(seconds);

//   var timeout = (then.getTime() - now.getTime());

//   setTimeout(function() { window.location.reload(true); }, timeout);
// }

// refreshAt(8,0,0)
